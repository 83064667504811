import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './Dashboard.css';
import { getPersonScheduledMeetings } from "../../api/personApi";
import { useAuthContext } from "../../App";
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from "react-router-dom";
import SearchBar from "../search/SearchBar";
import MeetingCounter from "../organisms/meetingcounter/MeetingCounter";
import '../../Main.css';
import StyledTable from "../molecules/table/styledtable/StyledTable";
import { DateUtils } from "../../utils/DateUtils";
import { getDashboardDataByPersonId } from "../../api/dashboardApi";
import { DashboardDataResponse, PieChartValues } from "../../types/dashboard";
import { getUIText } from "../../types/meetingStatus";

type PieChartData = {data: {
    title: string;
    value: number;
    color: string;
}[]};

const Dashboard: React.FC = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [dashboardTableData, setDashboardTableData] = useState<string[][]>([]);
    const [tableRowIds, setTableRowIds] = useState<string[]>([]);
    const [dashboardData, setDashboardData] = useState<DashboardDataResponse>({    
        totalMeetingsNumber: 0,
        totalMeetingsScheduled: 0,
        totalMeetingsHeld: 0,
        totalMeetingsInProcess: 0,
        totalMoneyAquiredInCents: 0,
    });
    const [dashboardPieChartData, setDashboardPieChartData] = useState<PieChartValues>({    
        scheduled: 0,
        inProcess: 0,
        held: 0,
    });
    const DASHBOARD_TABLE_HEADERS = ['First Name', 'Last Name', 'Job Title', 'Company Name', 'Meeting Date Time', 'Status'];
    

    useEffect(() => {
        if (user && user.personId !== undefined) {
            getPersonScheduledMeetings(user.personId)
                .then(response => {
                    const tableData: React.SetStateAction<string[][]> = [];
                    const ids: React.SetStateAction<string[]>  = [];

                    response.data.map((meeting) => {
                        const meetingRow = [
                            meeting.expert ? meeting.expert.name : '-',
                            meeting.expert ? meeting.expert.lastName : '-',
                            meeting.expert ? (meeting.expert.occupationList ? (meeting.expert.occupationList.length > 0 ? meeting.expert.occupationList[0].title : '-') : '-') : '-',
                            meeting.expert ? (meeting.expert.occupationList ? (meeting.expert.occupationList.length > 0 ? meeting.expert.occupationList[0].companyName : '-') : '-') : '-',
                            meeting.meetingDateTime? DateUtils.formatReadableDateTime(meeting.meetingDateTime) : '-',
                            meeting.meetingSubstatus ? getUIText(meeting.meetingSubstatus) : '-'
                        ];

                        tableData.push(meetingRow);
                        ids.push(meeting.meetingId ? meeting.meetingId : '');
                    })

                    setDashboardTableData(tableData);
                    setTableRowIds(ids);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            getDashboardDataByPersonId(user.personId)
                .then(response => {
                    setDashboardData(response.data);
                    setDashboardPieChartData({
                        scheduled: response.data.totalMeetingsNumber !== 0 ? Math.round(response.data.totalMeetingsScheduled / response.data.totalMeetingsNumber * 100) : 0,
                        inProcess: response.data.totalMeetingsNumber !== 0 ? Math.round(response.data.totalMeetingsInProcess / response.data.totalMeetingsNumber * 100) : 0,
                        held: response.data.totalMeetingsNumber !== 0 ? Math.round(response.data.totalMeetingsHeld / response.data.totalMeetingsNumber * 100) : 0,
                    })
                })
        }
    }, [user]);

    const Legend = (data: PieChartData) => (
        <div className="chart-legend">
          {data.data.map((entry, index) => (
            <div key={index} className="legend-item">
              <span className="legend-color" style={{ backgroundColor: entry.color }}></span>
              <span className="legend-title">{entry.title}: {entry.value}%</span>
            </div>
          ))}
        </div>
    );

    const goToSearchResult = (searchText: string) => {
        navigate(`/search/result?keywords=${searchText}`);
    }
      

    function goToMeeting(meetingId: string): void {
        navigate(`/meetings/${meetingId}`);
    }

    return (
        <>
            {user && user.personId !== undefined && (
            <Container fluid style={{ maxWidth: '90%' }}>
                <Row style={{ paddingTop: "40px"}}>
                    <h4>Welcome to <span className="main-color">Introductable{user.personName ? `, ${user.personName}` : ''}</span>!</h4>
                </Row>
                <Row className="justify-content-center mb-2" style={{ paddingTop: "20px"}}>
                    <Col>
                        <MeetingCounter meetingsNumber={dashboardData.totalMeetingsNumber} title="Total Meetings"/>
                    </Col>
                    <Col>
                        <MeetingCounter meetingsNumber={dashboardData.totalMeetingsInProcess} title="In Process"/>
                    </Col>
                    <Col>
                        <MeetingCounter meetingsNumber={dashboardData.totalMeetingsScheduled} title="Meetings Scheduled"/>
                    </Col>
                    <Col>
                        <MeetingCounter meetingsNumber={dashboardData.totalMeetingsHeld} title="Meetings Held"/>
                    </Col>
                </Row>
                <Row className="first-row">
                <Col md={9}>
                    <StyledTable 
                                tableTitle='Scheduled Meetings'
                                tableHeaders={DASHBOARD_TABLE_HEADERS} 
                                tableData={dashboardTableData} 
                                linkUrl={'/meetings'}
                                linkTitle='View All'
                                tableRowsIds={tableRowIds}
                                onClickRow={goToMeeting}
                            />
                </Col>
                <Col md={3}>
                    <Container className="styled-chart-wrapper">
                        <Row style={{ paddingTop: "15px", paddingLeft: "15px"}}>
                            <h5>Meeting Stats</h5>
                        </Row>
                        <Row>
                            <Col>
                            <PieChart
                                data={[
                                    { value: dashboardData.totalMeetingsNumber === 0 ? 1 : dashboardData.totalMeetingsInProcess, color: '#FFD3C2' },
                                    { value: dashboardData.totalMeetingsNumber === 0 ? 1 : dashboardData.totalMeetingsScheduled, color: '#FFAF8F' },
                                    { value: dashboardData.totalMeetingsNumber === 0 ? 1 : dashboardData.totalMeetingsHeld, color: '#F34804' },
                                ]}
                                lineWidth={80}
                                animate           
                                totalValue={dashboardData.totalMeetingsNumber === 0 ? 3 : dashboardData.totalMeetingsNumber}
                                radius={40}
                                />
                            </Col>
                            <Col className="d-flex justify-content-md-center align-items-center">
                            <Legend data={[
                                { title: 'In Process', value: dashboardPieChartData.inProcess, color: '#FFD3C2' },
                                { title: 'Scheduled', value: dashboardPieChartData.scheduled, color: '#FFAF8F' },
                                { title: 'Held', value: dashboardPieChartData.held, color: '#F34804' },

                            ]}/>
                            </Col>
                        </Row>
                    </Container>
                    <Row style={{ paddingTop: "15px", paddingLeft: "15px"}}>
                        <p>Total money in pending status: <span className="main-color">0$</span></p>
                    </Row>
                    <Row style={{ paddingTop: "15px", paddingLeft: "15px"}}>
                        <p>Total money received: <span className="main-color">0$</span></p>
                    </Row>
                </Col>
                </Row>
                <Row style={{ paddingTop: "20px", paddingBottom: "20px"}}>
                    <SearchBar onSearch={goToSearchResult} />
                </Row>
            </Container>
            )}
        </>
    )
};

export default Dashboard;