import React from 'react';
import './BrutalTruthSalesCycle.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';

import blogImage from '../../../content/blog/brutaltruthsalescycles/BrutalTruthSalesCyclesImage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const BrutalTruthSalesCycle: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='brutal-truth-sales-cycle-title'><h2>The Brutal Truth About <span className="main-color">Long Enterprise Sales Cycles</span> (And Why You Suck)</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="brutal-truth-sales-cycle-title-image" alt="BrutalTruthSalesCycleImage" />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Closing any day now…umm ok.
                    </p>
                </Row>
                <Row>
                    <p>
                        Even with greater information parity, sales cycles are getting longer, not shorter. According to Gartner, the average B2B sales cycle has stretched to a mind-numbing 4-6 months in 2024, up from 3-4 months just five years ago. Ouch.
                    </p>
                </Row>
                <Row>
                    <p>
                        But here's the thing - it doesn't have to be this way. And no, I'm not about to sell you some miracle cure that'll turn your 6-month nightmare into an overnight success story. Instead, let's talk about what actually works, backed by cold, hard data (and a dash of sanity).
                    </p>
                </Row>
                <Row>
                    <p>
                        First, let's address the elephant in the room: 82% of enterprise deals are now decided by buying committees of 6-10 people, according to Forrester. That's like trying to get a group of cats to agree on their favorite flavor of water. This is where tools like Introductable can help by helping sales teams engage key decision-makers earlier in the process with a built-in foundation of credibility, rather than playing an endless game of corporate whack-a-mole. But this isn’t a sales pitch, so show me the data.
                    </p>
                </Row>
                <Row>
                    <p>
                        Here's what's actually moving the needle in 2025:
                    </p>
                </Row>
                <Row>
                    <p>
                        Early stakeholder alignment is king. Companies using collaborative buying platforms are seeing 27% shorter sales cycles on average. Why? Because when you get all those cats in the same room early, they're more likely to play nice together.
                    </p>
                </Row>
                <Row>
                    <p>
                        The proof is in the numbers: Organizations implementing structured discovery processes close deals 30% faster than their peers. And no, "structured discovery" doesn't mean sending the same generic email to 500 people and praying for responses.
                    </p>
                </Row>
                <Row>
                    <p>
                        Speaking of which - here's a stat that'll keep you up at night: 67% of sales cycles are derailed by stakeholders who weren't identified early enough in the process. That's like planning an entire wedding only to find out your fiancé has an identical twin who also needs to approve the cake flavor.
                    </p>
                </Row>
                <Row>
                    <p>
                        But here's where it gets interesting: Companies who rigorously identify all stake holders (not just a casual question ie “Who else will need to sign off?” report a 35% reduction in "surprise" stakeholders popping up late in the game. Because, the only surprises we want in enterprise sales are the good kind - like finding out your prospect already has budget approval.
                    </p>
                </Row>
                <Row>
                    <p>
                        The real kicker? Deals with early executive sponsorship close 40% faster than those without. But here's the part nobody talks about - getting that executive sponsorship isn't about having the fanciest pitch deck or the smoothest talk track. It's about understanding the business impact you can provide and communicating it in a way that resonates with their actual priorities.
                    </p>
                </Row>
                <Row>
                    <p>
                        So what's the bottom line? The key to shortening enterprise sales cycles isn't about moving faster - it's about moving smarter. It's about mapping out your stakeholder landscape before you're knee-deep in proof-of-concept quicksand. It's about understanding that every week spent in discovery is worth a month saved in negotiations.
                    </p>
                </Row>
                <Row>
                    <p>
                        And perhaps most importantly, it's about accepting that while enterprise sales will never be as quick as you were to get away from that one relative who is in a MLM, they don't have to feel like you're trying to negotiate which way the toilet paper roll should unroll.
                    </p>
                </Row>
                <Row>
                    <p>
                        Remember: The goal isn't to eliminate the complexity of enterprise sales - it's to manage it before it manages you. And with the right tools and approach, you might just find yourself closing deals while your competitors are still trying to figure out who needs to sign off on the NDA.
                    </p>
                </Row>
                <Row>
                    <p>
                        Now, if you'll excuse me, I need to go check on that one deal that's been "just two weeks away" from closing since last quarter. #introductable #enterprisesales #salescycles
                    </p>
                </Row>

                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default BrutalTruthSalesCycle;
